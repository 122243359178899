import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import { FormContext } from "../../../App";
import * as yup from "yup";
import { Container, Paper, FormControl, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import TermAndCondition from "components/atoms/TermAndCondition";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import "./style.scss";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import PostService from "utility/postService";
import LeafBox from "components/atoms/LeafBox";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName } from "hooks";
import debounce from "lodash/debounce";
import { scrollToTop, modalScrollToTop } from '../../../utility/functions';

// Import SVG Icons.
import { OneStoryIcon, TwoStoryIcon, ThreeStoryIcon, SmallIcon, MediumIcon, LargeIcon, LFRenter, LFHomeOwner } from "components/atoms/Icons";
import ProgressBar from "components/atoms/ProgressBar";
import { FormContextSelfSchedulerStepper } from "../Self-Scheduler/SelfScheduler";
import { FormContextMultiStepper } from "./MultiStepper";

const Step1 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || "ZipChecker";
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';
  const formRef = useRef(null);

  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  let zipCodeValidationStatus = 'invalid';
  const product = useProduct();
  const isModalClose = props.isModalClose;

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();

  const houseTypeEnable =  props.dataSet?.housetype ? props.dataSet.housetype : 'disable';
  const blockRenterEnable =  props.dataSet?.blockrenter ? props.dataSet.blockrenter : 'disable';

  const additionalFormContext = (props.component === 'SelfScheduler' || props.modalComponent === 'SelfScheduler') ? FormContextSelfSchedulerStepper : FormContextMultiStepper;
  const { setMarketoData } = useContext(additionalFormContext);

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const marketoFlag = useRef(null);

  const loadMarketoData = async() => {
    if ((marketoFlag.current === undefined ||
      marketoFlag.current === null ||
      marketoFlag.current === false)) {
      try {
        const loadData = PostService.loadDataMarketo();
        (loadData) && loadData.then((data) => {
          if (data !== undefined) {
            setMarketoData(data)
          }
        });
        marketoFlag.current = true;
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  // Debounce the function to prevent rapid calls
  const debouncedLoadMarketoData = debounce(loadMarketoData, 300); // 300ms delay

  useEffect(() => {
    if (!isModalClose) {
      debouncedLoadMarketoData();
    }

    // Cleanup to cancel any pending debounced calls
    return () => {
      debouncedLoadMarketoData.cancel();
    };
  }, [isModalClose, debouncedLoadMarketoData]);

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && (formData.zipCode !== null) && !(localStorage.getItem("zipCode"))) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipCode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      })
  });

  const buttonSubmit = (e) => {

    // Scroll to the Top.
    scrollToTop(formRef);

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'ZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);

      modalScrollToTop(props.modalComponent);
    }
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'zipCode':
        zipCode = event.target.value;
        break;
      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleHomeSizeImageClick = (value, setFieldValue) => {
    setFieldValue("homeLevel", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleSquareFootImgClick = (value, setFieldValue) => {
    setFieldValue("squareFootage", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleRenterOwnerClick = (value, setFieldValue) => {
    setFieldValue("houseType", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)
      }`,
    borderRadius: 8,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        homeLevel: "",
        housetype: "",
        squareFootage: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        if (blockRenterEnable === 'enable' && values.houseType === 'Rent') {

          // Scroll to the Top.
          scrollToTop(formRef);

          setActiveStepIndex('renter');
        }
        else {
          const cookies = await PostService.getUTMAttributes();
          const additionalInformation = {
            guid: cookies.guid,
            utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
          };
          const data = { ...formData, ...values, ...additionalInformation };
          setFormData(data);

          if (userJourneyTracking) {
            let stepNumber = activeStepIndex + 1;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = activeStepIndex + 2;
          }
            // Passing lf.form.status event to DataLayer.
            DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
          }

          // Passing lf.form.assessment event to DataLayer.
          DataLayerService.formAssessmentEvent(data);

          if (servicetype === 'enable') {
            setActiveStepIndex(activeStepIndex + 1);
          }
          else if (roofshape === 'enable') {
            setActiveStepIndex(activeStepIndex + 2);
          }
          else {
            setActiveStepIndex(activeStepIndex + 3);
          }

          // Scroll to the Top.
          scrollToTop(formRef);

          // Passing lf.form.start event to DataLayer.
          DataLayerService.formStartEvent(form, location, data);
        }
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, errors }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start step1"
              onSubmit={handleSubmit}
              ref={formRef}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              {formData.zipCode ? (
                <div className="area-info p-2 mb-4">
                  <div className="fs-6">
                    We can’t wait for you to join over 1 million satisfied
                    customers!
                  </div>
                </div>
              ) : (
                ""
              )}

              <h3 className="fw-semibold c--dark-charcoal">
                Start your FREE no-obligation estimate &mdash; good for
                one full year!
              </h3>
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label= {formData.zipCode ? false : true }
              />
              <Divider className="divider" />

              <FormControl component="fieldset">
                <label>Levels on Your Home</label>
                <Field name="homeLevel">
                  {() => (
                    <LeafBox>
                      <OneStoryIcon handleClick={() =>
                        handleHomeSizeImageClick(
                          "oneStory",
                          setFieldValue
                        )
                      }></OneStoryIcon>
                      <TwoStoryIcon
                        handleClick={() =>
                          handleHomeSizeImageClick(
                            "twoStory",
                            setFieldValue
                          )
                        }
                      >
                      </TwoStoryIcon>
                      <ThreeStoryIcon
                        handleClick={() =>
                          handleHomeSizeImageClick(
                            "threeStory",
                            setFieldValue
                          )
                        }
                      >
                      </ThreeStoryIcon>
                    </LeafBox>
                  )}
                </Field>
              </FormControl>
              {houseTypeEnable === 'enable' ?
                <div>
                  <Divider className="divider" />
                  <FormControl component="fieldset">
                    <label>Do you rent or own your house?</label>
                    <div>
                      <Field name="housetype">
                        {() => (
                          <LeafBox>
                            <LFRenter handleClick={() =>
                              handleRenterOwnerClick ("Rent" , setFieldValue)
                            } />
                            <LFHomeOwner handleClick={() =>
                              handleRenterOwnerClick ("Own" , setFieldValue)
                            } />
                          </LeafBox>
                        )}
                      </Field>
                    </div>
                  </FormControl>
                </div>
              : ''}
              <Divider className="divider" />
              <LeafBox>
                <label>Estimated Square Footage of Home</label>
                <Field name="squareFootage">
                  {() => (
                    <LeafBox>
                      <SmallIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "1999",
                            setFieldValue
                          )
                        }
                      >
                      </SmallIcon>
                      <MediumIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "2000",
                            setFieldValue
                          )
                        }
                      >
                      </MediumIcon>
                      <LargeIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "3000",
                            setFieldValue
                          )
                        }
                      >
                      </LargeIcon>
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>
              <LeafBox>
                <LeafButton buttonType={"primary"} type="submit" handleClick={buttonSubmit} isSubmitting={isSubmitting} style={{
                  width: "100%"
                }}>
                  Next
                </LeafButton>
                <ProgressBar customClass="" {...props} />
                <TermAndCondition />
              </LeafBox>
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step1;
