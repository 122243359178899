import React, { useContext } from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import AlertMessage from "components/atoms/AlertMessage";
import SelfSchedulerScript from "components/atoms/SelfSchedulerScript";
import Divider from "@mui/material/Divider";
import { useComponentName } from "hooks";
import { ReactComponent as ListArrowIcon } from "components/Forms/form-icons/list-arrow-icon.svg";
import { LeafButton } from "components/atoms/LeafButtons";
import LeafBox from "components/atoms/LeafBox";
import DataLayerService from "utility/dataLayerService";
import { FormContext } from "App";
import { v4 as uuidv4 } from 'uuid';

const ThankYouSelfScheduler = (props) => {
  const phoneNumber ='1-888-701-5497';
  const telPhoneNumber = phoneNumber.replace('-', '');
  const { formData } = useContext(FormContext);
  const zipCode = formData.zipCode;
  const guid = formData.guid;
  const vertical = 'LHWS';
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  if (userJourneyTracking) {
    let stepNumber = 5;
    if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
      stepNumber = 6;
    }
    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 5, 'completed');
  }

  const selfSchedulerID = `self-scheduler-${uuidv4()}`;

  return (
      <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
        <StyledFormContainer className="thankyou-wrap fw-normal fs-1">
            <SelfSchedulerScript zipCode={zipCode} guid={guid} verticalID={vertical} selfSchedulerID={selfSchedulerID} />
            <AlertMessage message={'Thank you! Your information has been received.'} severity={'success'} />

            <h2 className="fw-normal mb-0 text-2xl text-break lh-lg">
              We'll be calling you soon to schedule your free water test and consultation.
            </h2>
            <p>Our water specialists will make recommendations for improving your water and provide you with a free, no-obligation quote.</p>
            <ul className="list-unstyled ms-5">
              <li><span className="bullet-icon ms-n1"><ListArrowIcon/></span>Our specialists will make recommendations for improving your water and provide you with a free, no-obligation quote.</li>
              <li><span className="bullet-icon ms-n1"><ListArrowIcon/></span> We are committed to your safety.{" "}
              <a href="https://www.leafhomewatersolutions.com/covid/">
                Learn about our COVID-19 protocol.
              </a></li>
            </ul>

            <div id={selfSchedulerID}></div>
            <Divider className="divider" />

            <p className="step5-sub-text">
              Can't wait to schedule your free estimate? <br />
              Give us a call at{" "}
              <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>
            </p>
            <LeafBox className="tel-link">
              <LeafButton
                buttonType={"tertiary"}
                handleClick={handleCallNow}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "auto",
                  marginRight: "auto"
                }}
                type="submit"
              >
                <span>Call Now</span>
              </LeafButton>
              <TermAndCondition />
            </LeafBox>
          </StyledFormContainer>
      </Container>
  );
}

export default ThankYouSelfScheduler;
