import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../App";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/system";
import DataLayerService from "utility/dataLayerService";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";

// Import SVG Icons.
import { FlatRoof, SlopedRoof } from "components/atoms/Icons";
import { LeafButton } from "components/atoms/LeafButtons";
import { useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";

const RoofShape = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';
  const product = useProduct();

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  const handleImageClick = (value, setFieldValue) => {
    setFieldValue("roofShape", value);
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        roofShape: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);

        setActiveStepIndex(activeStepIndex + 1);

        if (userJourneyTracking) {
          let stepNumber = activeStepIndex;
          if (servicetype === 'enable') {
            stepNumber += 1;
          }

          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber += 1;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex roof-shape flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h3 class="fw-semibold c--dark-charcoal">What type of roof do you have?</h3>
              <LeafBox>
                <label className="font-medium text-green-600">
                  This will help us with your project.
                </label>
                <Field name="roofShape">
                  {() => (
                    <LeafBox>
                      <SlopedRoof handleClick={() =>
                        handleImageClick("Sloped", setFieldValue)
                      } />
                      <FlatRoof handleClick={() =>
                        handleImageClick("Flat", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>

              <LeafButton buttonType={"primary"} type="submit" isSubmitting={isSubmitting} style={{
                width: "100%"
              }}>
                Next
              </LeafButton>
              <ProgressBar {...props} />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default RoofShape;
