import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { FormContext } from "../../../App";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/system";
import DataLayerService from "utility/dataLayerService";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";

// Import SVG Icons.
import { ReplacementIcon, ProtectionIcon, CleaningIcon } from "components/atoms/Icons";
import { LeafButton } from "components/atoms/LeafButtons";
import { useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";

const ServiceType = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const product = useProduct();
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  let services = [];
  const handleImageClick = (value, setFieldValue) => {
    const servicetypeindex = services.indexOf(value);
    if (servicetypeindex > -1) {
      services.splice(servicetypeindex, 1);
    }
    else {
      services.push(value);
    }
    setFieldValue("serviceType", services);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        serviceType: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);

        if (roofshape === 'enable') {
          setActiveStepIndex(activeStepIndex + 1);
        }
        else {
          setActiveStepIndex(activeStepIndex + 2);
        }

        if (userJourneyTracking) {
          let stepNumber = activeStepIndex + 1;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber += 1;
          }

          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex service-type flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h3 class="fw-semibold c--dark-charcoal">What gutter services are you looking for?</h3>
              <LeafBox>
                <label className="font-medium text-green-600">
                  Choose one or more options.
                </label>
                <Field name="serviceType">
                  {() => (
                    <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                      <ReplacementIcon handleClick={() =>
                        handleImageClick("Replacement", setFieldValue)
                      } />
                      <ProtectionIcon handleClick={() =>
                        handleImageClick("Protection", setFieldValue)
                      } />
                      <CleaningIcon handleClick={() =>
                        handleImageClick("Cleaning", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>

              <LeafButton buttonType={"primary"} type="submit" isSubmitting={isSubmitting} style={{
                width: "100%"
              }}>
                Next
              </LeafButton>
              <ProgressBar {...props} />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default ServiceType;
