const TermAndConditionGarageFlooring = () => {
  let siteUrl = 'https://www.leafhome.com';
  const privacyURL =  'https://privacy.leafhome.com/';

  return (
    <p className="text-slate-600 text-xs mt-3 form-disclaimer ms-0 me-0 mw-100">By clicking Next, I consent to receive marketing prerecorded and automated calls from Leaf Home Garage Flooring at the phone number provided and agree to the
    <a href={`${siteUrl}/terms-of-use/`} target="_blank" rel="noreferrer"><u>Terms of use</u></a> and <a href={privacyURL} target="_blank" rel="noreferrer"><u>Privacy Policy</u></a>. Consent is not a condition of purchase.</p>
  );
}

export default TermAndConditionGarageFlooring;
