import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import * as yup from "yup";
import { Container, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import "./style.scss";
import PostService from "utility/postService";
import DataLayerService from "utility/dataLayerService";
import validation from "utility/validation";
import TermOfServiceComponent from "components/atoms/TermOfService";
import EmailInput from "components/atoms/EmailInput";
import PhoneNumberInput from "components/atoms/PhoneNumberInput";
import TextInput from "components/atoms/TextInput";
import GeneralQuestionSubjects from "components/atoms/GeneralQuestionSubjects";
import sanitize from "sanitize-html";
import { useCountry, useBrand, useReCaptchaScore } from "hooks";
import { LeafButton } from "components/atoms/LeafButtons";
import ReCAPTCHA from "react-google-recaptcha";
import { scrollToTop } from '../../../../utility/functions';

const GeneralQuesForm = (props) => {
  const { activeStepIndex, setActiveStepIndex } =
    useContext(FormContext);
  let errorMsg = '';
  let captchaScore = null;
  const country = useCountry();
  const brand = useBrand();
  const formRef = useRef(null);

  // Disabling recaptcha.
  const reCaptchaShow = process.env.PANTHEON_ENVIRONMENT === 'live' ? (process.env.REACT_APP_RECAPTCHA_SHOW_PROD === 'enable') : (process.env.REACT_APP_RECAPTCHA_SHOW_NON_PROD === 'enable');

  const sanitizeOptions = {
    allowedTags: [],
    allowedAttributes: {},
  };

  const ValidationSchema = yup.object().shape({
    phoneNumber: yup
      .string()
      .matches(/^\(\d{3}\) \d{3}-\d{4}$/, "Phone Number is not valid.")
      .required("Phone Number is required."),
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  const handleOnFocus = (event) => {
    // Passing lf.form.start event to DataLayer.
    DataLayerService.formStartEvent('standard', 'support-form');

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent('standard', 'support-form');
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const recaptchaRef = useRef();

  const reCaptchaScoreConstant = useReCaptchaScore();
  return (
    <Formik
      initialValues={{
        firstName: "",
        lastName: "",
        emailAddress: "",
        phoneNumber: "",
        QuestionComments: "",
        selectedOption: "",
        termsOfService: false,
      }}
      validationSchema={ValidationSchema}
      onSubmit={async (values, actions) => {
        let captchaValue = '';

        if (reCaptchaShow) {
          // Validate if the user is not a Robot.
          const RecaptchaVerification = await validation.RecaptchaVerification();
          captchaScore = RecaptchaVerification.score;
          if (captchaScore < reCaptchaScoreConstant) {
            captchaValue = recaptchaRef.current.getValue();
          }
          else {
            captchaValue = RecaptchaVerification.token;
          }
      }

        if (captchaValue  || !reCaptchaShow) {
          let submittedValues = {}

          try {
            let response = {}
            const subjectValue = values.subject ? values.subject : 'General Question/Comment';

            if (brand === 'lhss' || brand === 'lhws') {
              let htmlText = '<p>New Form is submitted:</p>';
              htmlText = '<ul>';
              htmlText += '<li>First Name: ' + sanitize(values.firstName, sanitizeOptions) + '</li>';
              htmlText += '<li>Last Name: ' + sanitize(values.lastName, sanitizeOptions) + '</li>';
              htmlText += '<li>Email Address: ' + sanitize(values.emailAddress, sanitizeOptions) + '</li>';
              htmlText += '<li>Phone Number: ' + sanitize(values.phoneNumber, sanitizeOptions) + '</li>';
              htmlText += '<li>Subject: ' + sanitize(subjectValue, sanitizeOptions) + '</li>';
              htmlText += '<li>Comments: ' + sanitize(values.QuestionComments, sanitizeOptions) + '</li>';
              htmlText += '</ul>';

              submittedValues = {
                html: htmlText,
                subject: "Contact us - " + subjectValue,
                brand: brand
              }
              response = await PostService.sendMail('send-mail', submittedValues);

              if (response.success === false) {
                errorMsg = "Sorry, we couldn't send mail. Please contact site administrator for details.";
                actions.setSubmitting(false);
              }
            }
            else {
              submittedValues = {
                'firstName': values.firstName,
                'lastName': values.lastName,
                'emailAddress': values.emailAddress,
                'phoneNumber': values.phoneNumber,
                'subject': subjectValue,
                'comments': values.QuestionComments,
                'web_lead_source': "",
                'Web Lead Source': "",
                'landing_page': window.location.pathname,
                'country': 'US',
                'recaptcha_token': captchaValue,
                'recaptcha_score': captchaScore,
              }
              response = await PostService.postData('contact-us', submittedValues, true);

              if (response.success === false) {
                errorMsg = "Sorry, there was an error during submission. Please try again.";
                actions.setSubmitting(false);
              }
            }

            if (response.success === true) {
              // Passing lf.form.success event to DataLayer.
              DataLayerService.formSuccessEvent('standard', 'contact', 'support-form');
              setActiveStepIndex(activeStepIndex + 1);

              // Scroll to the Top.
              scrollToTop(formRef);
            }
            else {
              errorMsg = "Sorry, there was an error during submission. Please try again.";
              actions.setSubmitting(false);
            }
          } catch (error) {
            console.log("Error in sending data");
            console.log(error);
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <div className="service-support-form">
          <Container maxWidth={isMobile ? "xs" : "sm"}>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              onFocus={
                (event) => { handleOnFocus(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h3 className="heading">Send Us a Message!</h3>
              {errorMsg ? (<div className="border border-danger border-1 p-2 mb-4 error-msg fw-normal">{errorMsg}</div>) : ""}
              <TextInput fieldName={'firstName'} label={'First Name'} placeholder={"E.g., John"} />
              <TextInput fieldName={'lastName'} label={'Last Name'} placeholder={"E.g., Smith"} />
              <EmailInput fieldName={'emailAddress'} />
              <PhoneNumberInput fieldName={'phoneNumber'} />
              <GeneralQuestionSubjects></GeneralQuestionSubjects>

              <div className="field-container">
                <label>Questions/Comments</label>
                <span className="optional">(optional)</span>
                <Field
                  as="textarea"
                  name="QuestionComments"
                  className="form-control-textarea"
                  placeholder={"Additional Questions/Comments"}
                />
              </div>
              {country === 'ca' ? (
                <TermOfServiceComponent />
              ) : ''}

              {reCaptchaShow && (
                <div className="recaptcha">
                  {captchaScore !== null && captchaScore < reCaptchaScoreConstant && (
                    <>
                      <ReCAPTCHA
                        ref={recaptchaRef}
                        size="normal"
                        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY_V2}
                      />
                      <p className="error-msgs">reCAPTCHA verification failed. Please try again.</p>
                    </>
                  )}
                </div>
              )}

              {brand === 'lhss' ?
                <LeafButton buttonType={"primary"}  type="submit" name="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Submitting") : ("Send Email")}
                </LeafButton>
                : ''
              }

              { brand === 'lhws' ?
                <LeafButton buttonType={"secondary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                {isSubmitting ? ("Submitting") :  'Send Email'}
                </LeafButton>
                : ''
              }

              { (brand !== 'lhss' && brand !== 'lhws' ) ?
               <LeafButton buttonType={"tertiary"} type="submit" name="submit" isSubmitting={isSubmitting}>
                  {isSubmitting ? ("Submitting") : ("Send Email")}
                </LeafButton>
                : ''
              }

              {brand === 'lhss' ?
                <div className="message-wrap"><p>
                  Want to get in touch via email? Send us a message at
                  <a href="mailto:support@leafhomesafetysolutions.com">support@leafhomesafetysolutions.com</a>
                  with your phone number, zip code, and a description of your experience.
                </p>
                </div> : ''}
              {brand === 'lhws' ?
                <div className="message-wrap"><p>
                  Want to get in touch via email? Send us a message at
                  <a href="mailto:support@leafhomewatersolutions.com ">support@leafhomewatersolutions.com </a>
                  with your phone number, zip code, and a description of your experience.
                </p>
                </div> : ''}

            </Form>
          </Container>
        </div>
      )}
    </Formik>
  );
}

export default GeneralQuesForm;
