import { Container, Paper, styled, useMediaQuery, useTheme } from "@mui/material";
import React, { useRef } from "react";
import LeafBox from "../LeafBox";
import { LeafButton } from "../LeafButtons";
import { useProduct } from "hooks";
import { scrollToTop } from '../../../utility/functions';

const ThankYouRenter = ({activeStep}) => {
  const product = useProduct();
  const renterProductType = (product === 'lhws' ? 'water test' : 'gutter inspection');
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const formRef = useRef(null);

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
    height: (isMobile ? "auto" : 580),
  }));

  const handlBackNow = () => {
    // Scroll to the Top.
    scrollToTop(formRef);
    activeStep(0);
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
      <StyledFormContainer className="thankyou-wrap fw-normal fs-1">
        <div className="render-owner-thankyou" ref={formRef}>
          <h2 className="fw-normal mb-2 text-2xl text-break lh-lg">We’re sorry, we can only serve homeowners at this time.</h2>
          <p>If you’re currently renting a house or live in an apartment building, you can ask your landlord to contact us for a free {renterProductType}.</p>
          <LeafBox className="tel-link">
            <LeafButton
              buttonType={"tertiary"}
              handleClick={handlBackNow}
              data-blockrenter="enable"
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "auto",
                marginRight: "auto"
              }}
              type="submit"
            >
              <span>Go Back</span>
            </LeafButton>
          </LeafBox>
        </div>
      </StyledFormContainer>
    </Container>
  );
}

export default ThankYouRenter;
