
import React, { useContext, useEffect } from "react";
import { FormContext } from "../../../../../App";
import LHWSSingleStepEstimate from './LHWSSingleStepEstimate';
import FinalStep from './FinalStep';
import TrustedFormScript from "components/atoms/TrustedFormScript";

const SingleStepperLHWS = (props) => {
  const { activeStepIndex, setVLeafhome } = useContext(FormContext);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });
  let stepContent;
  switch (activeStepIndex) {
    case 1:
      stepContent = <FinalStep {...props} />;
      break;
    case 0:
      stepContent = <LHWSSingleStepEstimate {...props} />;
      break;
    default:
      break;
  }

  return (
    <>
      <div className="leafhome-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </>
  );
}


export default SingleStepperLHWS;
