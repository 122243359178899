import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Container, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName } from "hooks";
import { scrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { HomeOwnerIcon, FamilyMemberIcon, CareTakerIcon, YesIcon, NoIcon } from "components/atoms/Icons";

import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import ProgressBar from "components/atoms/ProgressBar";
import { FormContext } from "App";

const ProjectDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContext);
  const product = 'lhss';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const formRef = useRef(null);

  const handleRoleTypeClick = (value, setFieldValue) => {
    setFieldValue("roleType", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleFinancingClick = (value, setFieldValue) => {
    setFieldValue("financing", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 520,
    width: "100%",
    height: "auto",
  }));


  return (
    <Formik
      initialValues={{
        homeOwner: "",
        financing: "",
      }}

      onSubmit={async (values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveLHSSStepIndex('final');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 4;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 5;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 3, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);

      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, errors }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h3>Just a few more questions about your project.</h3>
              <LeafBox>
                <label>What's your role?</label>
                  <Field name="roleType">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <HomeOwnerIcon handleClick={() =>
                          handleRoleTypeClick("homeOwner", setFieldValue)
                        } />
                        <FamilyMemberIcon handleClick={() =>
                          handleRoleTypeClick("familyMember", setFieldValue)
                        } />
                        <CareTakerIcon handleClick={() =>
                          handleRoleTypeClick("careTaker", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
              </LeafBox>
              <Divider className="divider" />

              <LeafBox>
                <label>Are you interested in financing?</label>
                  <Field name="financing">
                    {() => (
                      <LeafBox component="fieldset">
                        <YesIcon handleClick={() =>
                          handleFinancingClick("yes", setFieldValue)
                        } />
                        <NoIcon handleClick={() =>
                          handleFinancingClick("no", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
              </LeafBox>
              <Divider className="divider" />
              <LeafBox>
                <LeafButton
                  buttonType={"primary"}
                  type="submit"
                  style={{
                    width: "100%"
                  }}
                  isSubmitting={isSubmitting}
                >
                Next
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default ProjectDetailsStep;
