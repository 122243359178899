import React, { useState, createContext, useContext } from "react";
import InitialStep from './InitialStep';
import CustomerGroupStep from './CustomerGroupStep';
import CustomerDetailsStep from './CustomerDetailsStep';
import ProjectDetailsStep from './ProjectDetailsStep';
import FinalStep from './FinalStep';
import ThankYou from './thankyou';
import "../global-brand.scss";
import "./style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import { FormContext } from "App";

export const FormContextLHSSMultiStepEstimate = createContext();

const LHSSMultiStepEstimate = (props) => {
  const { activeLHSSStepIndex } = useContext(FormContext)
  const [ marketoData, setMarketoData ] = useState({});

  let stepContent;
  switch (activeLHSSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep {...props} />;
      break;
    case 'ThankYou':
      stepContent = <ThankYou {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return (
    <FormContextLHSSMultiStepEstimate.Provider
      value={{ marketoData, setMarketoData  }}
    >
      <div className="leaffilter-forms leaf-forms lhss-estimate-forms">
        <div className="forms-content">{stepContent}</div>
      </div>
      <TrustedFormScript />
    </FormContextLHSSMultiStepEstimate.Provider>
  );
}

export default LHSSMultiStepEstimate;
