import React, { useContext } from "react";
import { FormContext } from "../../../../App";
import GeneralQuesForm from './GeneralQuesForm';
import ThankYouForm from './ThankYouForm';
import LHSSThankYou from "./LHSSThankYou";
import LHWSThankYou from "./LHWSThankYou";
import { useBrand } from 'hooks';

const ServiceStepper = (props) => {
  const brand = useBrand();
  const { activeStepIndex } = useContext(FormContext);
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <GeneralQuesForm {...props} />;
      break;
    case 1:
      if (brand === 'lhss') {
        stepContent = <LHSSThankYou {...props} />;
      }
      else if (brand === 'lhws') {
        stepContent = <LHWSThankYou {...props} />;
      }
      else {
        stepContent = <ThankYouForm {...props} />;
      }
      break;
    default:
      break;
  }

  return <div className="leaffilter-forms leaf-forms general-form">{stepContent}</div>;
}


export default ServiceStepper;
