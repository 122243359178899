import { Form, Formik } from "formik";
import React, { useContext } from "react";
import * as yup from "yup";
import {
  Container,
  Paper,
  useTheme
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import styled from "@emotion/styled";
import TextInput from "components/atoms/TextInput";
import EmailInput from "components/atoms/EmailInput";
import TermAndConditionGarageFlooring from "components/atoms/TermAndConditionGarageFlooring";
import { LeafButton } from "components/atoms/LeafButtons";
import LeafBox from '../../../atoms/LeafBox/index';
import ProgressBar from "components/atoms/ProgressBar";
import { useComponentName, useProduct } from "hooks";
import { FormContext } from "App";

const CustomerDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setActiveGarageFlooringStepIndex, formData, setFormData } = useContext(FormContext);
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  let fullName = '';
  let emailAddress = '';

  const ValidationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });


  const buttonSubmit = (e) => {
    const NameValidation = Validation.FullNameDataLayerValidation(fullName);
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);

    // Validation Error.
    const validationError = {
      ...NameValidation,
      ...EmailValidation
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);
    }
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
        break;

      case 'fullName':
        fullName = event.target.value;
        break;

      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 552,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        fullName: '',
        emailAddress: '',
        termsOfService: false,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const { fullName, emailAddress } = values;
        const [firstName, lastName] = fullName.trim().split(' ');
        const data = {
          ...formData,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
        };
        setFormData(data);
        setActiveGarageFlooringStepIndex('final');

        if (userJourneyTracking) {
          let stepNumber = 3;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 4;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 3, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                Who should we contact about this estimate?
              </h3>
              <TextInput fieldName={'fullName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
              <EmailInput fieldName={'emailAddress'} />
              {formData.groupLevel && formData.groupLevel !== 'neither' ?
                <LeafBox>
                  <h6 className="fw-normal tracking-wide pt-3">DISCOUNT APPLIED:</h6>
                  <div className="discount-text">
                    <ul className="discount-text lead text-black list-unstyled">
                      <li>10% OFF: Senior/Military</li>
                    </ul>
                  </div>
                </LeafBox>
              : ''}
              <Divider className="divider" />
              <LeafButton
                buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                type="submit"
                handleClick={() => buttonSubmit()}
                isSubmitting={isSubmitting}
              >
                Next
              </LeafButton>
              <ProgressBar customClass="" />
              <TermAndConditionGarageFlooring />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerDetailsStep;
