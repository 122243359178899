import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import * as yup from "yup";
import {
  Container,
  Paper,
  useTheme
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import styled from "@emotion/styled";
import TextInput from "components/atoms/TextInput";
import EmailInput from "components/atoms/EmailInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import LeafBox from '../../../atoms/LeafBox/index';
import ProgressBar from "components/atoms/ProgressBar";
import { useComponentName } from "hooks";
import { FormContext } from "App";
import { scrollToTop, modalScrollToTop } from '../../../../utility/functions';

const CustomerDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContext);
  const marketoData = props.marketoData;
  const product = 'lhss';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const formRef = useRef(null);

  let fullName = '';
  let emailAddress = '';

  const ValidationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  const buttonSubmit = (e) => {
    const NameValidation = Validation.FullNameDataLayerValidation(fullName);
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);

    // Validation Error.
    const validationError = {
      ...NameValidation,
      ...EmailValidation
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
        break;

      case 'fullName':
        fullName = event.target.value;
        break;

      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  let name = '';
  let email = '';
  if (marketoData !== undefined && marketoData !== null) {
    const firstname = marketoData.firstName?.trim() || '';
    const lastname = marketoData.lastName?.trim() || '';
    name = `${firstname} ${lastname}`.trim();
    email = marketoData.email ? marketoData.email : '';
  }

  return (
    <Formik
      initialValues={{
        fullName: name || '',
        emailAddress: email || '',
        termsOfService: false,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const { fullName, emailAddress } = values;
        const [firstName, lastName] = fullName.trim().split(' ');
        const data = {
          ...formData,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
        };
        setFormData(data);
        setActiveLHSSStepIndex('project');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 3;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 4;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 3, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, isSubmitting, errors }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h3>Who are we personalizing this <br />estimate for ?</h3>
              <TextInput fieldName={'fullName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
              <Divider className="divider" />
              <EmailInput fieldName={'emailAddress'} />
              <Divider className="divider" />
              <LeafBox>
                <h3>Applied Discounts For Your Estimate</h3>
                <div className="discount-text">
                  <ul>
                    <li>10% OFF Promo</li>
                    {formData.groupLevel && formData.groupLevel !== 'neither' ?
                      <li>+10% Senior/Military Discount</li> : ''}
                  </ul>
                </div>
              </LeafBox>
              <LeafBox>
                <LeafButton
                  type="submit"
                  buttonType={"primary"}
                  handleClick={() => buttonSubmit()}
                  isSubmitting={isSubmitting}
                  style={{
                    width: "100%"
                  }}
                >
                  Next
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerDetailsStep;
