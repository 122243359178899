import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import TermAndCondition from "components/atoms/TermAndCondition";
import { FormContext } from "../../../App";
import SelfSchedulerScript from "components/atoms/SelfSchedulerScript";
import { useProduct, useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";
import { v4 as uuidv4 } from 'uuid';

const ThankYouC = (props) => {
  const { activeStepIndex, formData } = useContext(FormContext);
  const zipCode = formData.zipCode;
  const guid = formData.guid;
  const vertical = 'LF';
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
  }));

  if (userJourneyTracking) {
    // Update the StepNumber for ShortLeadform.
    let stepNumber = activeStepIndex - 2;
    if (servicetype === 'enable') {
      stepNumber += 1;
    }
    if (roofshape === 'enable') {
      stepNumber += 1;
    }
    if ((componentName === 'ShortLeadForm') || (props.modalComponent === 'ShortLeadForm')) {
      stepNumber = activeStepIndex + 1;
    }
    if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
      stepNumber += 1;
    }

    // Number of required Fields.
    let requiredFields = 4;
    if (product === 'ca') {
      requiredFields = 5;
    }

    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, requiredFields, 'completed');
  }

  const selfSchedulerID = `self-scheduler-${uuidv4()}`;

  return (
    <>
      <SelfSchedulerScript zipCode={zipCode} guid={guid} verticalID={vertical} selfSchedulerID = {selfSchedulerID} />

      <Formik
        initialValues={{
        }}
      >
        {() => (
          <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
            <StyledFormContainer>
              <Form
                className="flex flex-col justify-center items-start thankyou-C"
              >
                <div className="step5-main-text">
                  Schedule your <br /> FREE consultation to get your <br /> NO OBLIGATION estimate now.
                </div>
                <br />

                <div id={selfSchedulerID}></div>

                <Divider className="divider" />
                <TermAndCondition />
              </Form>
            </StyledFormContainer>
          </Container>
        )}
      </Formik>
    </>
  );
}

export default ThankYouC;
