import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Container, FormControl, useTheme, Paper } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import DataLayerService from "utility/dataLayerService";
import { useComponentName } from "hooks";
import Divider from "@mui/material/Divider";

// Import SVG Icons.
import { LHSeniorIcon, LHMilitaryIcon, LHNeitherIcon } from "components/atoms/Icons";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import LeafBox from "components/atoms/LeafBox";
import ProgressBar from "components/atoms/ProgressBar";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { useVersion } from "hooks";
import { scrollToTop } from '../../../../../utility/functions';
import { FormContext } from "App";

const CustomerGroupStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContext);
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const v2 = useVersion();
  const formRef = useRef(null);

  let groupList = [];

  const handleImageClick = (value, setFieldValue) => {
    if (value !== 'neither') {
      const groupListIndex  = groupList.indexOf(value);
      if (groupListIndex > -1) {
        groupList.splice(groupListIndex, 1);
      }
      else {
        groupList.push(value);
      }
      setFieldValue("groupLevel", groupList);
    }
    else {
      groupList.splice(0, groupList.length);
      setFieldValue("groupLevel", value);
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));


  return (
    <Formik
      initialValues={{
        groupLevel: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setactiveLHWSStepIndex('details');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 2;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 3;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
              <Form
                className="flex flex-col justify-center items-start"
                onSubmit={handleSubmit}
                ref={formRef}
                onBlur={
                  (event) => {handleOnBlur(event)}
                }
              >
                {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
                <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
                <div className="step2">
                  <h3 className="fw-normal mb-3">
                    Let’s see if we can find you some discounts. Are you a senior, active service member or veteran?
                  </h3>
                </div>

                <FormControl component="fieldset">

                  <label className="small mb-3">Qualifying members will get 10% off their purchase.</label>
                    <Field name="groupLevel">
                    {() => (
                      <LeafBox style={{
                        marginBottom: "0",
                      }}>
                        <LHMilitaryIcon handleClick={() =>
                          handleImageClick("military", setFieldValue)
                        } />
                        <LHSeniorIcon handleClick={() =>
                          handleImageClick("senior", setFieldValue)
                        } />
                        <LHNeitherIcon handleClick={() =>
                          handleImageClick("neither", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
                </FormControl>
                <Divider className="divider" />
                <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                  type="submit"
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
                { v2 ?
                  <ProgressBarWithPercentage />
                  :
                  <ProgressBar customClass="" />
                }
                <TermAndCondition />
              </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerGroupStep;
