import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import * as yup from "yup";
import { Container, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";

// Import SVG Icons.
import { OneCar, TwoCar, ThreeCar, GFSmallIcon, GFMediumIcon, GFLargeIcon } from "components/atoms/Icons";

import styled from "@emotion/styled";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TermAndConditionGarageFlooring from "components/atoms/TermAndConditionGarageFlooring";
import LeafBox from "components/atoms/LeafBox";
import PostService from "utility/postService";
import { LeafButton } from "components/atoms/LeafButtons";
import { useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import debounce from "lodash/debounce";
import { FormContext } from "App";

const InitialStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  let zipCode = '';
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();
  const isModalClose = props.isModalClose;
  const { setActiveGarageFlooringStepIndex, formData, setFormData, setMarketoData } = useContext(FormContext);

  const marketoFlag = useRef(null);

  const loadMarketoData = async() => {
    if ((marketoFlag.current === undefined ||
      marketoFlag.current === null ||
      marketoFlag.current === false)) {
      try {
        const loadData = PostService.loadDataMarketo();
        (loadData) && loadData.then((data) => {
          if (data !== undefined) {
            setMarketoData(data);
          }
        });
        marketoFlag.current = true;
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  // Debounce the function to prevent rapid calls
  const debouncedLoadMarketoData = debounce(loadMarketoData, 300); // 300ms delay

  useEffect(() => {
    if (!isModalClose) {
      debouncedLoadMarketoData();
    }

    // Cleanup to cancel any pending debounced calls
    return () => {
      debouncedLoadMarketoData.cancel();
    };
  }, [isModalClose, debouncedLoadMarketoData]);

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== undefined) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      })
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'LeafHomeZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);
    }
  }

  const handleOnBlur = (event) => {
    zipCode = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleGarageSize = (value, setFieldValue) => {
    setFieldValue("garageSize", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleSquareFootImgClick = (value, setFieldValue) => {
    setFieldValue("squareFootage", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 552,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        product: "bdurable",
        garageSize: "",
        squareFootage: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setActiveGarageFlooringStepIndex('group');

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start step1"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
                Your free, no-obligation estimate is good for an entire year.
              </h3>
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label={formData.zipCode ? false : true}
              />

              <LeafBox>
                <label className="small mb-3">How big is your garage?</label>
                <div>
                  <Field name="garageSize">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <OneCar handleClick={() =>
                          handleGarageSize('One Car', setFieldValue)
                        } />

                        <TwoCar handleClick={() =>
                          handleGarageSize('Two Car', setFieldValue)
                        } />

                        <ThreeCar handleClick={() =>
                          handleGarageSize('Three Car', setFieldValue)
                        } />

                      </LeafBox>
                    )}
                  </Field>
                </div>
              </LeafBox>

              <LeafBox>
                <label className="small mb-3">How many square feet is it?</label>
                <Field name="squareFootage">
                  {() => (
                    <LeafBox>
                      <GFSmallIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "0-250",
                            setFieldValue
                          )
                        }
                      >
                      </GFSmallIcon>
                      <GFMediumIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "250-559",
                            setFieldValue
                          )
                        }
                      >
                      </GFMediumIcon>
                      <GFLargeIcon
                        handleClick={() =>
                          handleSquareFootImgClick(
                            "560+",
                            setFieldValue
                          )
                        }
                      >
                      </GFLargeIcon>
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>
              <Divider className="divider" />
                <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-medium'
                  type="submit"
                  handleClick={buttonSubmit}
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              <ProgressBar customClass="" />
              <TermAndConditionGarageFlooring />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
