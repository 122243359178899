import React, { useContext } from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import AlertMessage from "components/atoms/AlertMessage";
import SelfSchedulerScript from "components/atoms/SelfSchedulerScript";
import Divider from "@mui/material/Divider";
import { useComponentName } from "hooks";
import { ReactComponent as ListArrowIcon } from "components/Forms/form-icons/list-arrow-icon.svg";
import { LeafButton } from "components/atoms/LeafButtons";
import LeafBox from "components/atoms/LeafBox";
import DataLayerService from "utility/dataLayerService";
import { FormContext } from "App";
import { v4 as uuidv4 } from 'uuid';

const ThankYouSelfScheduler = (props) => {
  const phoneNumber ='1-888-701-5497';
  const telPhoneNumber = phoneNumber.replace('-', '');
  const { formData } = useContext(FormContext);
  const zipCode = formData.zipCode;
  const guid = formData.guid;
  const vertical = 'LHWS';
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  if (userJourneyTracking) {
    let stepNumber = 5;
    if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
      stepNumber = 6;
    }
    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 5, 'completed');
  }

  const styles = {
    position: 'absolute',
    marginLeft: '-30px',
    width: '20px',
    height: '20px',
  };

  const iconStyles = {
    marginLeft: '32px',
  }

  const selfSchedulerID = `self-scheduler-${uuidv4()}`;

  return (
      <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
        <StyledFormContainer className="thankyou-wrap fw-normal fs-1">
            <SelfSchedulerScript zipCode={zipCode} guid={guid} verticalID={vertical} selfSchedulerID={selfSchedulerID} />
            <LeafBox style={{width: 'calc(100% - 24px)'}}>
              <AlertMessage message={'Thank you! Your information has been received.'} severity={'success'} />
            </LeafBox>
            <h2 className="fw-normal mb-0 text-2xl text-break lh-lg">
              We'll be calling you soon to schedule your free water test and consultation.
            </h2>
            <p>Our water specialists will make recommendations for improving your water and provide you with a free, no-obligation quote.</p>
            <ul className="list-unstyled" style={iconStyles}>git
              <li><span className="bullet-icon ms-n1" style={styles}><ListArrowIcon/></span>Our specialists will make recommendations for improving your water and provide you with a free, no-obligation quote.</li>
            </ul>

            <div id={selfSchedulerID}></div>
            <Divider className="divider" />

            <p className="step5-sub-text">
              Can't wait to schedule your free estimate? <br />
              Give us a call at{" "}
              <span className="phone-number" onClick={handleCallNow}>{`${phoneNumber}`}</span>
            </p>
            <LeafBox className="tel-link">
              <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-semibold flex'
                  type="submit"
                  handleClick={handleCallNow}
                >Call Now
              </LeafButton>
              <TermAndCondition />
            </LeafBox>
          </StyledFormContainer>
      </Container>
  );
}

export default ThankYouSelfScheduler;
