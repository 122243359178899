const {
    REACT_APP_PANTHEON_ENVIRONMENT,
    REACT_APP_IS_DDEV_PROJECT,
    REACT_APP_DDEV_PRIMARY_URL,
    REACT_APP_EXPRESS_URL,
    REACT_APP_RETAIN_CERTIFICATE,
    REACT_APP_GITHUB_REPO
} = process.env;

export function getBaseUrl() {
    // If express URL is explicitly declared, use it. Otherwise, calculate it.
    if (REACT_APP_EXPRESS_URL) {
        return REACT_APP_EXPRESS_URL;
    }
    else if (REACT_APP_IS_DDEV_PROJECT) {
        console.log('Using DDEV primary url: ' + REACT_APP_DDEV_PRIMARY_URL);
        return REACT_APP_DDEV_PRIMARY_URL + ':3002';
    }
    else {
        return (REACT_APP_PANTHEON_ENVIRONMENT === 'live') ? ((REACT_APP_GITHUB_REPO === 'lf-formik-base') ? "https://forms.leaffilter.com" : "https://forms1.leaffilter.com") : `https://${REACT_APP_PANTHEON_ENVIRONMENT}-${REACT_APP_GITHUB_REPO}.appa.pantheon.site`;
    }
}

// Get the Self Scheduler link.
export function getSelfSchedulerLink() {
    let selfSchedulerLink = '';
    switch (REACT_APP_PANTHEON_ENVIRONMENT) {
        case 'live':
            selfSchedulerLink = 'https://ss.leafhome.com/widget.js';
            break;

        case 'multi-uat':
            selfSchedulerLink = 'https://ss-uat.leafhome.com/widget.js';
            break;

        case 'multi-qa':
            selfSchedulerLink = 'https://ss-qa.leafhome.com/widget.js';
            break;

        default:
            selfSchedulerLink = 'https://ss-dev.leafhome.com/widget.js';
            break;
    }

    return selfSchedulerLink;
}

/*
 * Decide if we want to use Sandbox script or
 * production script for Active Prospect.
*/
export function getactiveTestScriptAP() {
    let sandboxActiveProspect = 'sandbox';
    if (
        (REACT_APP_PANTHEON_ENVIRONMENT === 'live') ||
        (REACT_APP_RETAIN_CERTIFICATE === 'enable')
    ) {
        sandboxActiveProspect = 'nosandbox';
    }
    return sandboxActiveProspect;
}

// Scroll to Top of Form.
export function scrollToTop(formRef) {
    if ( formRef.current ) {
        formRef.current.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'start'
        });
    }
}

// Scroll to Top of Form for Modal Forms.
export function modalScrollToTop(modalComponent) {
    if (modalComponent) {
        const element = document.querySelector('.MuiDialog-container .leaf-forms');
        element.scrollIntoView(true);
    }
}
