import React from "react";
import { Helmet } from 'react-helmet';
import { getSelfSchedulerLink } from '../../../utility/functions';

const SelfSchedulerScript = ({ zipCode, guid, verticalID, selfSchedulerID = 'self-scheduler' }) => {
  const selfSchedulerLink = getSelfSchedulerLink();

  return (
    <>
      <Helmet>
        {/* Insert your script tag in the head */}
        <script type="text/javascript">
        {`
          (function (w, d, s, o, f, js, fjs) {
            w[o] = w[o] || function () { (w[o].q = w[o].q || []).push(arguments); };
            js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
            js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
          }(window, document, 'script', '__lhs_ss', '${selfSchedulerLink}'));
        `}
        </script>

        <script type="text/javascript">
        {`
          __lhs_ss('init', { selector: '#${selfSchedulerID}', mode: 'web', postalCode: '${zipCode}', webSessionId: '${guid}', vertical: '${verticalID}' });
        `}
        </script>

      </Helmet>
    </>
  );
}

export default SelfSchedulerScript;
