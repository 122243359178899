import React, { useContext, useState } from "react";
import { Container, Paper, useTheme } from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import { useGetPhoneNumber } from "utility/countryOverride";
import TermAndCondition from "components/atoms/TermAndCondition";
import { ReactComponent as PhoneIcon } from "components/Forms/form-icons/phone-icon.svg";
import { LeafButton } from "components/atoms/LeafButtons";
import AlertMessage from "components/atoms/AlertMessage";
import { useProduct, useComponentName } from "hooks";
import DataLayerService from "utility/dataLayerService";
import { FormContext } from "../../../App";

const ThankYou = (props) => {
  const product = useProduct();
  const { activeStepIndex } = useContext(FormContext);
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const phoneNumber = useGetPhoneNumber();
  const telPhoneNumber = phoneNumber.replace('-', '');
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';

  const handleCallNow = () => {
    window.location.href = "tel:+" + telPhoneNumber;
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)} ${isMobile ? theme.spacing(2) : theme.spacing(6)}`,
    borderRadius: 8,
    width:"100%",
    height: showProtocolInfo ? 650  : (isMobile ? "auto" : 580),
  }));

  const [showProtocolInfo, setShowProtocolInfo] = useState(false);

  const toggleProtocolInfo = () => {
    setShowProtocolInfo(!showProtocolInfo);
  };

  if (userJourneyTracking) {
    // Update the StepNumber for ShortLeadform.
    let stepNumber = activeStepIndex - 2;
    if (servicetype === 'enable') {
      stepNumber += 1;
    }
    if (roofshape === 'enable') {
      stepNumber += 1;
    }
    if ((componentName === 'ShortLeadForm') || (props.modalComponent === 'ShortLeadForm')) {
      stepNumber = activeStepIndex + 1;
    }
    if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
      stepNumber += 1;
    }

    // Number of required Fields.
    let requiredFields = 4;
    if (product === 'ca') {
      requiredFields = 5;
    }

    // Passing lf.form.status event to DataLayer.
    DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, requiredFields, 'completed');
  }

  return (
    <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
      <StyledFormContainer className="thankyou-wrap fw-normal fs-1 thankyou-B">

        <div className="step5-main-text">
          We'll be calling you soon to <br /> schedule a FREE estimate.<br />
          OR <br /> <u>SKIP THE CALL</u> by scheduling <br /> your NO OBLIGATION consultation below.
        </div>
        <br />

        <p className="step5-sub-text">
          Our estimator will inspect and measure your gutters, discuss
          your needs, and provide you with a custom, no-obligation quote.
        </p>
        <br />

        <Divider className="divider" />
        <p className="step5-sub-text">
          Can't wait to schedule your free estimate? <br />
          Give us a call at{" "}
          <span className="phone-number">{`${phoneNumber}`}</span>
        </p>
        <LeafButton
          buttonType={"secondary"}
          handleClick={handleCallNow}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
            marginRight: "auto",
            marginBottom: "20px",
          }}
          type="submit"
        >
          <PhoneIcon className="phone-icon"></PhoneIcon>
          <span style={{ marginLeft: "0.5rem" }}>Call Now</span>
        </LeafButton>

        <AlertMessage style={{ marginBottom: "-25px" }} message={'Your information has been received!'} severity={'success'} />

        <p className="step5-sub-text">
          We are committed to your safety.{" "}
          <u onClick={toggleProtocolInfo}>
            Learn about our COVID-19 protocol.
          </u>
        </p>

        {showProtocolInfo && (
          <ul>
            <li>Our team limits contact, including handshakes.</li>
            <li>
              We frequently sanitize and encourage regular washing of
              hands.
            </li>
            <li>
              We practice social distancing and require team members to
              wear masks.
            </li>
          </ul>
        )}

        <TermAndCondition />
      </StyledFormContainer>
    </Container>
  );
}

export default ThankYou;
