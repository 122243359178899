import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import {
  Container,
  FormControl,
  useTheme,
  Paper
} from "@mui/material";
import { useMediaQuery } from "@mui/material";
import Divider from "@mui/material/Divider";
import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName } from "hooks";

// Import SVG Icons.
import { LHYes, LHNo, LHImmediately, LHWeeks, LHFlexible } from "components/atoms/Icons";
import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import ProgressBar from "components/atoms/ProgressBar";
import { useVersion } from "hooks";
import ProgressBarWithPercentage from 'components/atoms/ProgressBarWithPercentage';
import { scrollToTop } from '../../../../../utility/functions';
import { FormContext } from "App";

const ProjectDetailsStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setactiveLHWSStepIndex, formData, setFormData } = useContext(FormContext);
  const product = 'lhws';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const v2 = useVersion();
  const formRef = useRef(null);

  const handleProjectTimeClick = (value, setFieldValue) => {
    setFieldValue("projectTime", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleFinancingClick = (value, setFieldValue) => {
    setFieldValue("financing", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: isMobile ? "100%" : 520,
    width: "100%",
    height: isMobile ? "auto" : 720,
  }));

  return (
    <Formik
      initialValues={{
        homeOwner: "",
        financing: "",
      }}

      onSubmit={async (values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setactiveLHWSStepIndex('final');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 4;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 5;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 4, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);

      }}
    >
      {({ handleSubmit, setFieldValue, values, touched, errors, isSubmitting }) => (
        <Container maxWidth={isMobile ? "xs" : "sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3">
              You're almost there! Just a few more steps.
              </h3>
              <FormControl component="fieldset">
                <label className="small mb-3">When are you looking to start this project?</label>
                <div>
                  <Field name="roleType">
                    {({ field }) => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <LHImmediately handleClick={() =>
                          handleProjectTimeClick("immediately", setFieldValue)
                        } />
                        <LHWeeks handleClick={() =>
                          handleProjectTimeClick("1-2 Weeks", setFieldValue)
                        } />
                        <LHFlexible handleClick={() =>
                          handleProjectTimeClick("Flexible", setFieldValue)
                        } />

                      </LeafBox>
                    )}
                  </Field>
                </div>
              </FormControl>

              <LeafBox>
                <FormControl component="fieldset">
                  <label className="small mb-3">Are you interested in financing?</label>
                  <div>
                    <Field name="financing">
                      {({ field }) => (
                        <LeafBox style={{
                          display: "flex",
                          alignItems: "start",
                          marginBottom: '0',
                        }}>
                          <LHYes handleClick={() =>
                            handleFinancingClick("yes", setFieldValue)
                          } />
                          <LHNo handleClick={() =>
                            handleFinancingClick("no", setFieldValue)
                          } />
                        </LeafBox>
                      )}
                    </Field>
                  </div>
                </FormControl>
              </LeafBox>
              <Divider className="divider" />
              <LeafButton
                  buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                  type="submit"
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              { v2 ?
                <ProgressBarWithPercentage />
                :
                <ProgressBar customClass="" />
              }
              <TermAndCondition />
            </Form>
            </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default ProjectDetailsStep;
