import React, { createContext, useState } from "react";
import ThankYou from './ThankYou';
import PrivacyForm from "./PrivacyForm";

export const FormContextPrivacy = createContext();

const Privacy = (props) => {
  const [activeStepIndex, setactiveStepIndex] = useState('');
  let stepContent;
  switch (activeStepIndex) {

    case 'thankyou':
        stepContent = <ThankYou {...props} />;
        break;
    default:
      stepContent = <PrivacyForm />
      break;
  }

  return (
    <FormContextPrivacy.Provider value={{activeStepIndex, setactiveStepIndex}}>
      <div className="leaffilter-forms leaf-forms privacy-form">{stepContent}</div>
    </FormContextPrivacy.Provider>
  );
}

export default Privacy;
