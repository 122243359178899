import { Field, Form, Formik } from "formik";
import React, { useContext, useEffect, useRef } from "react";
import * as yup from "yup";
import { Container, Paper } from "@mui/material";
import Divider from "@mui/material/Divider";
import "./style.scss";
import DataLayerService from "utility/dataLayerService";
import Validation, { useZipCodeValidation } from "utility/validation";
import debounce from "lodash/debounce";
import { scrollToTop, modalScrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { StairLiftsIcon, WalkInIcon, ReadyToHireIcon, PlanningIcon } from "components/atoms/Icons";

import styled from "@emotion/styled";
import ZipCodeInput from "components/atoms/ZipcodeInput";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";
import PostService from "utility/postService";
import { LeafButton } from "components/atoms/LeafButtons";
import { useProduct, useComponentName } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import { FormContext } from "App";
import { FormContextLHSSMultiStepEstimate } from "./LHSSMultiStepEstimate";

const InitialStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const zipSource = props.dataSet?.zipsource || "localzip";
  let zipCode = '';
  let productList = [];
  let zipcodeInputValue = '';
  let serviceAreaFlag = false;
  const product = useProduct();
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  let zipCodeValidationStatus = 'invalid';
  const isModalClose = props.isModalClose;
  const formRef = useRef(null);

  // Zipcode Validation based on the Country Code.
  const zipCodeValidation = useZipCodeValidation();
  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContext);
  const { setMarketoData } = useContext(FormContextLHSSMultiStepEstimate);
  const marketoFlag = useRef(null);

  const loadMarketoData = async() => {
    if ((marketoFlag.current === undefined ||
      marketoFlag.current === null ||
      marketoFlag.current === false)) {
      try {
        const loadData = PostService.loadDataMarketo();
        (loadData) && loadData.then((data) => {
          if (data !== undefined) {
            setMarketoData(data)
          }
        });
        marketoFlag.current = true;
      }
      catch (error) {
        console.log(error);
      }
    }
  }

  // Debounce the function to prevent rapid calls
  const debouncedLoadMarketoData = debounce(loadMarketoData, 300); // 300ms delay

  // Call the debounced function
  useEffect(() => {
    if (!isModalClose) {
      debouncedLoadMarketoData();
    }

    // Cleanup to cancel any pending debounced calls
    return () => {
      debouncedLoadMarketoData.cancel();
    };
  }, [isModalClose, debouncedLoadMarketoData]);

  const ValidationSchema = yup.object().shape({
    zipCode: yup
      .string()
      .matches(zipCodeValidation.validationPattern, zipCodeValidation.validationMessage)
      .required(`${zipCodeValidation.zipCodeText} is required.`)
      .test('available-zipcode', zipCodeValidation.restrictedValidationMessage, async function (zipcode) {
        if (!(formData.zipCode) && (zipcodeInputValue !== zipcode) && (zipCodeValidation.validationPattern.test(zipcode))) {
          serviceAreaFlag = await PostService.checkAvailableService(zipcode, product, zipSource);
          zipcodeInputValue = zipcode;
          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if (!serviceAreaFlag) {
            DataLayerService.formFailEvent(form, location, {'zipCode': ['OOA restriction for ' + zipcode + ' failed.']});
          }
          if (userJourneyTracking) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcodeInputValue, 'completed', zipCodeValidationStatus);
          }
          // Return true if the validation passes, false if it fails
          return serviceAreaFlag;
        }
        else {
          if (formData.zipCode) {
            return true;
          }

          zipCodeValidationStatus = (serviceAreaFlag === true) ? 'valid' : 'invalid';
          if ((userJourneyTracking) && formData.zipCode !== undefined) {
            DataLayerService.zipCodeEventTracking(formName, subformName, product, zipcode, 'completed', zipCodeValidationStatus);
          }
          return serviceAreaFlag;
        }
      })
  });

  const buttonSubmit = (e) => {

    // Validation Error.
    const zipCodevalidationError = Validation.ZipValidationCodeDataLayerValidation(zipCode, zipCodeValidation.validationPattern);

    if ((Object.keys(zipCodevalidationError).length !== 0) && (componentName !== 'ZipChecker')) {
      DataLayerService.formFailEvent(form, location, zipCodevalidationError);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleOnBlur = (event) => {
    zipCode = event.target.value;
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleProductIconClick = (value, setFieldValue) => {
    const productIndex = productList.indexOf(value);
    if (productIndex > -1) {
      productList.splice(productIndex, 1);
    }
    else {
      productList.push(value);
    }
    setFieldValue("product", productList);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleProjectStatusClick = (value, setFieldValue) => {
    setFieldValue("projectStatus", value);

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 520,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        zipCode: formData.zipCode
          ? formData.zipCode
          : localStorage.getItem("zipCode") || '',
        product: "",
        projectStatus: "",
      }}
      validationSchema={ValidationSchema}
      validateOnBlur={true}
      validateOnChange={false}
      onSubmit={async (values) => {
        const cookies = await PostService.getUTMAttributes();
        const additionalInformation = {
          utm_campaign: cookies.utm_campaign ? cookies.utm_campaign.toUpperCase() : '',
        };
        const data = { ...formData, ...values, ...additionalInformation };
        setFormData(data);

        // Passing lf.form.assessment event to DataLayer.
        DataLayerService.formAssessmentEvent(data);

        setActiveLHSSStepIndex('group');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 1;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 2;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start step1"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h3>
                Start your FREE no-obligation <br /> estimate &mdash; good for
                one full year!
              </h3>
              <ZipCodeInput
                fieldName={'zipCode'}
                style={{
                  display: formData.zipCode ? "none" : "inline"
                }}
                label={formData.zipCode ? false : true}
              />

              <Divider className="divider" />

              <LeafBox>
                <label>What product(s) are you interested in?</label>
                <div>
                  <Field name="product">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <StairLiftsIcon handleClick={() =>
                          handleProductIconClick("Stair Lifts", setFieldValue)
                        } />

                        <WalkInIcon handleClick={() =>
                          handleProductIconClick("Tub or Shower", setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
                </div>
              </LeafBox>
              <Divider className="divider" />
              <LeafBox>
                <label>Choose the appropriate status for this project</label>
                <div>
                  <Field name="projectStatus">
                    {() => (
                      <LeafBox style={{
                        display: "flex",
                        alignItems: "start"
                      }}>
                        <ReadyToHireIcon handleClick={() =>
                          handleProjectStatusClick(1, setFieldValue)
                        } />

                        <PlanningIcon handleClick={() =>
                          handleProjectStatusClick(2, setFieldValue)
                        } />
                      </LeafBox>
                    )}
                  </Field>
                </div>
              </LeafBox>
              <LeafBox>
                <LeafButton
                  buttonType={"primary"}
                  type="submit"
                  handleClick={buttonSubmit}
                  style={{
                    width: "100%"
                  }}
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default InitialStep;
