import React, { useContext, createContext, useEffect, useState } from "react";
import { FormContext } from "../../../../App";
import InitialStep from './InitialStep';
import CustomerGroupStep from './CustomerGroupStep';
import CustomerDetailsStep from './CustomerDetailsStep';
import FinalStep from './FinalStep';
import ThankYou from './thankyou';
import "../global-style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";

export const FormContextGarageFlooring = createContext();

const GarageFlooring = (props) => {
  const { activeGarageFlooringStepIndex } = useContext(FormContext);
  const { setVLeafhome } = useContext(FormContext);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });
  const [ marketoData, setMarketoData ] = useState({});

  let stepContent;
  switch (activeGarageFlooringStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep {...props} />;
      break;
    case 'ThankYou':
      stepContent = <ThankYou {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return (
    <FormContextGarageFlooring.Provider
      value={{ marketoData, setMarketoData }}
    >
      <div className="leafhome-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextGarageFlooring.Provider>
  );
}

export default GarageFlooring;
