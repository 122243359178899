import { createContext, useState, useContext, useEffect } from "react";
import InitialStep from "./InitialStep";
import CustomerGroupStep from "./CustomerGroupStep";
import CustomerDetailsStep from "./CustomerDetailsStep";
import ProjectDetailsStep from "./ProjectDetailsStep";
import FinalStep from "./FinalStep";
import ThankYou from "./thankyou";
import ThankYouSelfScheduler from "./thankyouselfscheduler"
import "./style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";
import { FormContext } from "../../../../App";
import ThankYouRenter from "components/atoms/ThankYouRenter";

export const FormContextLHWSMultiStepEstimate = createContext({
  marketoData: {}
});

const  LHWSMultiStepEstimate = (props) => {

  const {activeLHWSStepIndex, setactiveLHWSStepIndex} = useContext(FormContext);
  const [ rentHome, setRentHome ] = useState(false);
  const [ marketoData, setMarketoData ] = useState({});
  const selfScheduler = props.dataSet?.selfscheduler ? props.dataSet.selfscheduler : 'disable';
  const formVersion = props.dataSet?.version ? true : false;
  const { v2, setV2 } = useContext(FormContext);
  useEffect(() => {
    setV2(formVersion);
  });


  let stepContent;
  switch (activeLHWSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep {...props} />;
      break;
    case 'ThankYou':
      // Option to decide whether we want Self Scheduler or not.
      if (selfScheduler === 'enable' && !rentHome) {
        stepContent = <ThankYouSelfScheduler {...props} />;
      }
      else {
        stepContent = <ThankYou {...props} />;
      }
      break;
    case 'renter':
      stepContent = <ThankYouRenter activeStep={setactiveLHWSStepIndex} {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return (
    <FormContextLHWSMultiStepEstimate.Provider
      value={{ rentHome, setRentHome, marketoData, setMarketoData }}
    >
      <div className={`leaffilter-forms leaf-forms lhws-estimate-forms ${v2 ? 'version-2' : ''}`}>
        <div className="forms-content">{stepContent}</div>
      </div>
      <TrustedFormScript />
    </FormContextLHWSMultiStepEstimate.Provider>
  );
}

export default LHWSMultiStepEstimate;