import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { Container, Paper } from "@mui/material";

import DataLayerService from "utility/dataLayerService";
import LeafBox from "components/atoms/LeafBox";
import { useComponentName } from "hooks";
import { scrollToTop } from '../../../../utility/functions';

// Import SVG Icons.
import { SeniorIcon, MilitaryIcon, NeitherIcon } from "components/atoms/Icons";

import styled from "@emotion/styled";
import TermAndCondition from "components/atoms/TermAndCondition";
import { LeafButton } from "components/atoms/LeafButtons";
import ProgressBar from "components/atoms/ProgressBar";
import { FormContext } from "App";

const CustomerGroupStep = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const { setActiveLHSSStepIndex, formData, setFormData } = useContext(FormContext);
  const product = 'lhss';
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const formRef = useRef(null);

  let groupList = [];

  const handleImageClick = (value, setFieldValue) => {
    if (value !== 'neither') {
      const groupListIndex = groupList.indexOf(value);
      if (groupListIndex > -1) {
        groupList.splice(groupListIndex, 1);
      }
      else {
        groupList.push(value);
      }
      setFieldValue("groupLevel", groupList);
    }
    else {
      groupList.splice(0, groupList.length);
      setFieldValue("groupLevel", value);
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(() => ({
    background: "#ffffff",
    borderRadius: 0,
    maxWidth: 520,
    width: "100%",
    height: "auto",
  }));


  return (
    <Formik
      initialValues={{
        groupLevel: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);
        setActiveLHSSStepIndex('details');

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 2;
          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 3;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, values, isSubmitting, errors }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <p className="promo-title">10% OFF Promo Applied</p>
              <div className="step2">
                <h3>
                  Does anyone in your household qualify for an extra 10% senior
                  or military discount?
                </h3>
              </div>

              <LeafBox>
                <label className="font-medium text-green-600">
                  Which of these applies?{" "}
                  <span className="optional">(optional)</span>
                </label>
                <Field name="groupLevel">
                  {() => (
                    <LeafBox>
                      <SeniorIcon handleClick={() =>
                        handleImageClick("senior", setFieldValue)
                      } />
                      <MilitaryIcon handleClick={() =>
                        handleImageClick("military", setFieldValue)
                      } />
                      <NeitherIcon handleClick={() =>
                        handleImageClick("neither", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>
              <LeafBox>
                <LeafButton
                  buttonType={"primary"}
                  type="submit"
                  style={{
                    width: "100%"
                  }}
                  isSubmitting={isSubmitting}
                >
                  Next
                </LeafButton>
              </LeafBox>
              <ProgressBar customClass="" />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default CustomerGroupStep;
