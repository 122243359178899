import { Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../../App";
import { FormContextMultiStepper } from "./MultiStepper";
import { FormContextSelfSchedulerStepper } from "../Self-Scheduler/SelfScheduler";
import * as yup from "yup";
import {
  Container,
  Paper
} from "@mui/material";

import Divider from "@mui/material/Divider";
import { styled } from "@mui/system";
import DataLayerService from "utility/dataLayerService";
import Validation from "utility/validation";
import TermOfServiceComponent from "components/atoms/TermOfService";
import TermAndCondition from "components/atoms/TermAndCondition";
import EmailInput from "components/atoms/EmailInput";
import TextInput from "components/atoms/TextInput";
import LeafBox from "components/atoms/LeafBox";
import { LeafButton } from "components/atoms/LeafButtons";
import { useCountry, useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";
import { scrollToTop, modalScrollToTop } from '../../../../utility/functions';

const Step3 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const country = useCountry();
  const product = useProduct();
  const { activeStepIndex, setActiveStepIndex, formData, setFormData } = useContext(FormContext);
  const additionalFormContext = (props.component === 'LeafHomeSelfScheduler' || props.modalComponent === 'LeafHomeSelfScheduler') ? FormContextSelfSchedulerStepper : FormContextMultiStepper;
  const { marketoData } = useContext(additionalFormContext);
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'LeafHomeZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const formRef = useRef(null);

  let fullName = '';
  let emailAddress = '';
  let emailOptIn = false;

  const ValidationSchema = yup.object().shape({
    fullName: yup
      .string()
      .trim()
      .matches(/[^\s\d]+ .+$/, "Full Name must include both First Name and Last Name.")
      .required('Full Name is required.'),
    emailAddress: yup
      .string()
      .matches(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, 'Email Address is not valid.')
      .required("Email Address is required."),
  });

  const buttonSubmit = (e) => {
    const NameValidation = Validation.FullNameDataLayerValidation(fullName);
    const EmailValidation = Validation.EmailDataLayerValidation(emailAddress);
    const TermsOfServiceValidation = Validation.TermsOfServiceDataLayerValidation(emailOptIn, country);

    // Validation Error.
    const validationError = {
      ...NameValidation,
      ...EmailValidation,
      ...TermsOfServiceValidation
    };

    if (Object.keys(validationError).length !== 0) {
      DataLayerService.formFailEvent(form, location, validationError);

      modalScrollToTop(props.modalComponent);
    }

    // Scroll to the Top.
    scrollToTop(formRef);
  }

  const handleOnBlur = (event) => {
    switch (event.target.name) {
      case 'emailAddress':
        emailAddress = event.target.value;
        break;

      case 'fullName':
        fullName = event.target.value;
        break;

      default:
        break;
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  let name = '';
  let email = '';
  if (marketoData !== undefined && marketoData !== null) {
    const firstname = marketoData.firstName ? marketoData.firstName : '';
    const lastname = marketoData.lastName ? marketoData.lastName : '';
    name = `${firstname + " " + lastname}`;
    name = name.trim();
    email = marketoData.email ? marketoData.email : '';
  }

  return (
    <Formik
      initialValues={{
        fullName: name
          ? name
          : '',
        emailAddress: email
          ? email
          : '',
        termsOfService: false,
      }}
      validationSchema={ValidationSchema}
      onSubmit={(values) => {
        const { fullName, emailAddress } = values;
        const [firstName, lastName] = fullName.trim().split(' ');

        const data = {
          ...formData,
          firstName: firstName,
          lastName: lastName,
          emailAddress: emailAddress,
        };
        setFormData(data);
        setActiveStepIndex(activeStepIndex + 1);

        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = 3;
          if ((componentName === 'LeafHomeZipChecker') || (componentName === 'ModalForm')) {
            stepNumber = 4;
          }

          // Number of required Fields.
          let requiredFields = 3;
          if (country === 'ca') {
            requiredFields = 4;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, requiredFields, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, errors, isSubmitting }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              ref={formRef}
              onSubmit={handleSubmit}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <h6 className="tracking-wide text-uppercase mb-4 fw-normal text-gray-600">Free Estimate</h6>
              <h3 className="fw-normal mb-3"> Who should we contact about this estimate? </h3>

              <TextInput fieldName={'fullName'} label={'Full Name'} placeholder={"E.g., John Smith"} />
              <EmailInput fieldName={'emailAddress'} />

              {country === 'ca' ? (
                <TermOfServiceComponent />
              ) : ''}

              {formData.groupLevel !== "" && formData.groupLevel !== 'neither' ? (
                <LeafBox>
                  <h6 className="fw-normal tracking-wide pt-3">DISCOUNT APPLIED:</h6>
                  <div className="discount-text lead text-black">10% OFF: Senior/Military</div>
                </LeafBox>
              ) : ""}
              <Divider className="divider" />
              <LeafButton
                buttonClass='rounded-3 btn-primary w-100 fw-semibold'
                type="submit"
                handleClick={() => buttonSubmit()}
                style={{
                  width: "100%"
                }}
              >
                Next
              </LeafButton>
              <ProgressBar />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step3;
