import React, { useContext, createContext, useEffect, useState } from "react";
import { FormContext } from "../../../../App";
import InitialStep from './InitialStep';
import CustomerGroupStep from './CustomerGroupStep';
import CustomerDetailsStep from './CustomerDetailsStep';
import ProjectDetailsStep from './ProjectDetailsStep';
import FinalStep from './FinalStep';
import ThankYou from './thankyou';
import "../global-style.scss";
import TrustedFormScript from "components/atoms/TrustedFormScript";

export const FormContextLHSSMultiStepEstimate = createContext();

const LHSSMultiStepEstimate = (props) => {
  const { activeLHSSStepIndex } = useContext(FormContext);
  const { setVLeafhome } = useContext(FormContext);
  const leahomeVersion = props.dataSet?.vleafhome ? props.dataSet?.vleafhome : true;
  useEffect(() => {
    setVLeafhome(leahomeVersion);
  });
  const [ marketoData, setMarketoData ] = useState({});

  let stepContent;
  switch (activeLHSSStepIndex) {
    case 'group':
      stepContent = <CustomerGroupStep {...props} />;
      break;
    case 'details':
      stepContent = <CustomerDetailsStep {...props} />;
      break;
    case 'project':
      stepContent = <ProjectDetailsStep {...props} />;
      break;
    case 'final':
      stepContent = <FinalStep {...props} />;
      break;
    case 'ThankYou':
      stepContent = <ThankYou {...props} />;
      break;
    default:
      stepContent = <InitialStep {...props} />;
      break;
  }

  return (
    <FormContextLHSSMultiStepEstimate.Provider
      value={{ marketoData, setMarketoData }}
    >
      <div className="leafhome-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextLHSSMultiStepEstimate.Provider>
  );
}

export default LHSSMultiStepEstimate;
