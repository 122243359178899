
import { useProduct, useVersionLeafhome } from "hooks";
import React, { useContext } from "react";
import { FormContext } from "App";

const ProgressBar = (props) => {

  const { activeStepIndex, activeLHSSStepIndex, activeLHWSStepIndex } = useContext(FormContext);
  const { activeLHSSStepIndex: activeLeafhomeLHSSStepIndex } = useContext(FormContext);
  const { activeLHWSStepIndex: activeLeafhomeLHWSStepIndex } = useContext(FormContext);
  const { activeGarageFlooringStepIndex: activeLeafhomeGarageFlooringStepIndex } = useContext(FormContext);
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';
  const customClass1 = "success";
  const customClass2 = "incomplete"
  let progressIndex = 0;
  const brand = useProduct();
  const vLeafhome = useVersionLeafhome();
  const product = vLeafhome ? 'leafhome' : brand;
  let backgroundColor = '#00853e';

  const lhssProgressSteps = [
    'group',
    'details',
    'project',
    'final'
  ];

  const garageFlooringProgressSteps = [
    'group',
    'details',
    'final'
  ];

  const progressSteps = [
    1,
    2,
    3,
    4,
    5
  ];

  switch(product) {

    case 'lhss':
      progressIndex  = lhssProgressSteps.indexOf(activeLHSSStepIndex);
      backgroundColor = '#3d7491';
      break;
    case 'lhws':
      progressIndex  = lhssProgressSteps.indexOf(activeLHWSStepIndex);
      backgroundColor = '#1e7ac4';
      break;
    case 'leafhome':
      if ( brand === 'lhss' ) {
        progressIndex  = lhssProgressSteps.indexOf(activeLeafhomeLHSSStepIndex);
      } else if ( brand === 'lhws' ) {
        progressIndex  = lhssProgressSteps.indexOf(activeLeafhomeLHWSStepIndex);
      } else if ( brand === 'bdurable' ) {
        progressIndex  = garageFlooringProgressSteps.indexOf(activeLeafhomeGarageFlooringStepIndex);
      } else {
        progressIndex = progressSteps.indexOf(activeStepIndex);
      }
      backgroundColor = '#FF5524';
      break;
    default:
      progressIndex = progressSteps.indexOf(activeStepIndex);
      if (servicetype === 'enable') {
        if ((progressIndex > 0) && (roofshape !== 'enable')) {
          progressIndex = progressSteps.indexOf(activeStepIndex) - 1;
        }
      }
      else if (roofshape === 'enable') {
        if (progressIndex > 0) {
          progressIndex = progressSteps.indexOf(activeStepIndex) - 1;
        }
      }
      else {
        if (progressIndex > 1) {
          progressIndex = progressSteps.indexOf(activeStepIndex) - 2;
        }
      }
      break;
  }

  return (
    <div className="mx-auto">
      <div className={`d-flex justify-content-center ${
        vLeafhome ? "pt-3" : "py-4"
      }`}>
        <div className="progress" style={{ width: "50%"}}>
          <div
            className={`progress-bar mr-2 ${
              progressIndex >= -1 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>

        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 0 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 1 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 2 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        <div className="spacer"></div>
        <div className="progress" style={{ width: "50%" }}>
          <div
            className={`progress-bar ${
              progressIndex >= 3 ? customClass1 : customClass2
            }`}
            role="progressbar"
            style={{ width: "100%", backgroundColor: `${
              customClass1 === 'success' ? backgroundColor : ''
            }`}}
          ></div>
        </div>
        {servicetype === 'enable' ?
          <>
            <div className="spacer"></div>
            <div className="progress" style={{ width: "50%" }}>
              <div
                className={`progress-bar ${
                  progressIndex >= 4 ? customClass1 : customClass2
                }`}
                role="progressbar"
                style={{ width: "100%", backgroundColor: `${
                  customClass1 === 'success' ? backgroundColor : ''
                }`}}
              ></div>
            </div>
          </>
        : ''}
        {roofshape === 'enable' ?
          <>
            <div className="spacer"></div>
            <div className="progress" style={{ width: "50%" }}>
              <div
                className={`progress-bar ${
                  progressIndex >= 5 ? customClass1 : customClass2
                }`}
                role="progressbar"
                style={{ width: "100%", backgroundColor: `${
                  customClass1 === 'success' ? backgroundColor : ''
                }`}}
              ></div>
            </div>
          </>
        : ''}
      </div>
    </div>
  );
}

export default ProgressBar;
