import React, { useContext, createContext, useState } from "react";
import { FormContext } from "../../../App";
import Step1 from './Step1';
import Step2 from './Step2';
import Step3 from './Step3';
import Step4 from './Step4';
import ServiceType from './ServiceType';
import RoofShape from './RoofShape';
import ThankYou from './ThankYou';
import TrustedFormScript from "components/atoms/TrustedFormScript";
import ThankYouB from './ThankYouB';
import ThankYouC from './ThankYouC';
import ThankYouRenter from "components/atoms/ThankYouRenter";
export const FormContextMultiStepper = createContext();

const MultiStepper = (props) => {
  const { activeStepIndex, setActiveStepIndex } = useContext(FormContext);
  const thankYouSection = props.dataSet?.thankyoumessage ? props.dataSet.thankyoumessage : '';
  const [ marketoData, setMarketoData ] = useState({}) // This will hold the mutable data

  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <Step1 {...props} />;
      break;

    case 1:
      stepContent = <ServiceType {...props} />;
      break;

    case 2:
      stepContent = <RoofShape {...props} />;
      break;

    case 3:
      stepContent = <Step2 {...props} />;
      break;

    case 4:
      stepContent = <Step3 {...props} />;
      break;

    case 5:
      stepContent = <Step4 {...props} />;
      break;

    case 6:
      switch (thankYouSection) {
        case 'test-B':
          stepContent = <ThankYouB {...props} />;
          break;

        case 'test-C':
          stepContent = <ThankYouC {...props} />;
          break;

        default:
          stepContent = <ThankYou {...props} />;
          break;
      }
      break;
    case 'renter':
      stepContent = <ThankYouRenter activeStep={setActiveStepIndex}  {...props} />;
      break;

    default:
      break;
  }

  return (
    <FormContextMultiStepper.Provider value={{marketoData, setMarketoData}}>
      <div className="leaffilter-forms leaf-forms">{stepContent}</div>
      <TrustedFormScript />
    </FormContextMultiStepper.Provider>
  );
}

export default MultiStepper;
