import { Field, Form, Formik } from "formik";
import React, { useContext, useRef } from "react";
import { FormContext } from "../../../App";
import { Container, Paper } from "@mui/material";
import { styled } from "@mui/system";
import DataLayerService from "utility/dataLayerService";
import TermAndCondition from "components/atoms/TermAndCondition";
import LeafBox from "components/atoms/LeafBox";
import { scrollToTop } from '../../../utility/functions';

// Import SVG Icons.
import { ReactComponent as FlagIcon } from "components/Forms/form-icons/flag.svg";

// Import SVG Icons.
import { SeniorIcon, MilitaryIcon, NeitherIcon } from "components/atoms/Icons";
import { LeafButton } from "components/atoms/LeafButtons";
import { useCountry, useComponentName, useProduct } from "hooks";
import ProgressBar from "components/atoms/ProgressBar";

const Step2 = (props) => {
  const location = props.dataSet?.location ? props.dataSet.location : "multi-step-modal-form";
  const form = props.dataSet?.form ? props.dataSet.form : "modal";
  const userJourneyTracking = props.dataSet?.tracking || '';
  const componentName = props.component || 'ZipChecker';
  const formName = useComponentName(componentName);
  const subformName = useComponentName(props.modalComponent);
  const roofshape = props.dataSet?.roofshape ? props.dataSet.roofshape : '';
  const servicetype = props.dataSet?.servicetype ? props.dataSet.servicetype : '';
  const formRef = useRef(null);

  const country = useCountry();
  const product = useProduct();

  const { activeStepIndex, setActiveStepIndex, formData, setFormData } =
    useContext(FormContext);

  let groupList = [];
  const handleImageClick = (value, setFieldValue) => {
    if (value !== 'neither') {
      const groupListIndex = groupList.indexOf(value);
      if (groupListIndex > -1) {
        groupList.splice(groupListIndex, 1);
      }
      else {
        groupList.push(value);
      }
      setFieldValue("groupLevel", groupList);
    }
    else {
      groupList.splice(0, groupList.length);
      setFieldValue("groupLevel", value);
    }

    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const handleOnBlur = (event) => {
    // Passing lf.form.interaction event to DataLayer.
    DataLayerService.formInteractionEvent(form, location);
  };

  const StyledFormContainer = styled(Paper)(({ theme }) => ({
    background: "#ffffff",
    padding: `${theme.spacing(2)}`,
    borderRadius: 8,
    width: "100%",
    height: "auto",
  }));

  return (
    <Formik
      initialValues={{
        groupLevel: "",
      }}
      onSubmit={(values) => {
        const data = { ...formData, ...values };
        setFormData(data);

        setActiveStepIndex(activeStepIndex + 1);
        // Scroll to the Top.
        scrollToTop(formRef);

        if (userJourneyTracking) {
          let stepNumber = activeStepIndex - 1;
          if (servicetype === 'enable') {
            stepNumber += 1;
          }
          if (roofshape === 'enable') {
            stepNumber += 1;
          }

          if ((componentName === 'ZipChecker') || (componentName === 'ModalForm')) {
            stepNumber += 1;
          }
          // Passing lf.form.status event to DataLayer.
          DataLayerService.formStatusEvent(formName, subformName, product, stepNumber, 1, 'notcompleted');
        }

        // Passing lf.form.start event to DataLayer.
        DataLayerService.formStartEvent(form, location, data);
      }}
    >
      {({ handleSubmit, setFieldValue, isSubmitting, errors }) => (
        <Container maxWidth={"sm"} disableGutters={true}>
          <StyledFormContainer>
            <Form
              className="flex flex-col justify-center items-start"
              onSubmit={handleSubmit}
              ref={formRef}
              onBlur={
                (event) => { handleOnBlur(event) }
              }
            >
              {isSubmitting && Object.keys(errors).length > 0 && scrollToTop(formRef)}
              <div className="step2">
                <h3 className="fw-semibold c--dark-charcoal">
                  Does anyone in your household qualify for an extra 10% senior
                  or military discount?
                </h3>
                {
                  country !== 'ca' ?
                    (<FlagIcon
                      alt="Discount"
                      className="ml-2"
                      style={{ width: "140px", height: "140px" }}
                    />) : ""
                }
              </div>

              <LeafBox>
                <label className="font-medium text-green-600">
                  Which of these applies?{" "}
                  <span className="optional">(optional)</span>
                </label>
                <Field name="groupLevel">
                  {() => (
                    <LeafBox>
                      <SeniorIcon handleClick={() =>
                        handleImageClick("senior", setFieldValue)
                      } />
                      <MilitaryIcon handleClick={() =>
                        handleImageClick("military", setFieldValue)
                      } />
                      <NeitherIcon handleClick={() =>
                        handleImageClick("neither", setFieldValue)
                      } />
                    </LeafBox>
                  )}
                </Field>
              </LeafBox>

              <LeafButton buttonType={"primary"} type="submit" isSubmitting={isSubmitting} style={{
                width: "100%"
              }}>
                Next
              </LeafButton>
              <ProgressBar {...props} />
              <TermAndCondition />
            </Form>
          </StyledFormContainer>
        </Container>
      )}
    </Formik>
  );
}

export default Step2;
