import React, { useContext } from "react";
import { FormContext } from "../../../../App";
import ServiceIssuesForm from './ServiceIssuesForm';
import ThankYouFormService from './ThankYouFormService';
import LHSSThankYouService from "./LHSSThankYouService";
import LHWSThankYouService from "./LHWSThankYouService";
import { useBrand } from "hooks";

const ServiceIssuesStepper = (props) => {
  const { activeStepIndex } = useContext(FormContext);
  const brand = useBrand();
  let stepContent;
  switch (activeStepIndex) {
    case 0:
      stepContent = <ServiceIssuesForm {...props}/>;
      break;
    case 1:
      if (brand === 'lhss') {
        stepContent = <LHSSThankYouService {...props} />;
      }
      else if (brand === 'lhws') {
        stepContent = <LHWSThankYouService {...props} />;
      }
      else {
        stepContent = <ThankYouFormService {...props} />;
      }
      break;
    default:
      break;
  }

  return (
    <div className="leaffilter-forms leaf-forms service-issues-form">{stepContent}</div>
  );
}


export default ServiceIssuesStepper;
